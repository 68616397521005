import React, { useEffect, useRef, useState} from 'react';
import one from "../../../../assets/CustomerAsset/CommonImage/mega-menu/01.svg";
import two from "../../../../assets/CustomerAsset/CommonImage/mega-menu/02.svg";
import three from "../../../../assets/CustomerAsset/CommonImage/mega-menu/03.svg";
import four from "../../../../assets/CustomerAsset/CommonImage/mega-menu/04.svg";
import five from "../../../../assets/CustomerAsset/CommonImage/mega-menu/05.svg";
import six from "../../../../assets/CustomerAsset/CommonImage/mega-menu/06.svg";
import seven from "../../../../assets/CustomerAsset/CommonImage/mega-menu/07.svg";
import eight from "../../../../assets/CustomerAsset/CommonImage/mega-menu/08.svg";
import nine from "../../../../assets/CustomerAsset/CommonImage/mega-menu/09.svg";
import twenty_two from "../../../../assets/CustomerAsset/CommonImage/mega-menu/25.svg";
import twenty_three from "../../../../assets/CustomerAsset/CommonImage/mega-menu/24.svg";
import twenty_four from "../../../../assets/CustomerAsset/CommonImage/mega-menu/26.svg";
import twenty_five from "../../../../assets/CustomerAsset/CommonImage/mega-menu/28.svg";

export default function CustomerNavigation (){
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [openDropdowns, setOpenDropdowns] = useState({});
    const sidebarRef = useRef(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const toggleDropdown = (index) => {
        setOpenDropdowns((prev) => ({
            ...prev,
            [index]: !prev[index],
        }));
    };


    const handleClickOutside = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
            setIsSidebarOpen(false);
            setOpenDropdowns({});
        }
    };

    useEffect(() => {
        if (isSidebarOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isSidebarOpen]);


    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (isMobile){
            setIsSidebarOpen(false)
            setOpenDropdowns({});
        }
    }, [isMobile]);


    const handleLinkClick = (e) => {
        e.preventDefault();
        const links = document.querySelectorAll('.submenu .mobile-menu-link');
        links.forEach(link => {
            link.style.color = '';
        });
        e.target.style.color = '#007bff';
    };

    return (
        <>

            <nav className="rts-header__menu" id="mobile-menu">
                <div className="hostie-menu">
                    <ul className="list-unstyled hostie-desktop-menu">
                        <li className="menu-item hostie-has-dropdown">
                            <a href="/" className="hostie-dropdown-main-element">Home</a>
                        </li>

                        <li className="menu-item hostie-has-dropdown mega-menu big">
                            <a href="#" className="hostie-dropdown-main-element">Pages</a>
                            <div className="rts-mega-menu">
                                <div className="wrapper">
                                    <div className="row g-0">
                                        <div className="col-lg-4">
                                            <ul className="mega-menu-item">
                                                <li>
                                                    <a href="#">
                                                        <img src={one} alt="icon"/>
                                                        <div className="info">
                                                            <p>About US</p>
                                                            <span>Get know about us </span>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        <img src={two} alt="icon"/>
                                                        <div className="info">
                                                            <p>Pricing </p>
                                                            <span>Shared Package price</span>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        <img src={three} alt="icon"/>
                                                        <div className="info">
                                                            <p>Contact</p>
                                                            <span>Contact with providers</span>
                                                        </div>
                                                    </a>
                                                </li>

                                            </ul>
                                        </div>

                                        <div className="col-lg-4">
                                            <ul className="mega-menu-item">
                                                <li>
                                                    <a href="#">
                                                        <img src={four} alt="icon"/>
                                                        <div className="info">
                                                            <p>Offers</p>
                                                            <span>Offers with providers</span>
                                                        </div>
                                                    </a>
                                                </li>

                                                <li>
                                                    <a href="#">
                                                        <img src={five} alt="icon"/>
                                                        <div className="info">
                                                            <p>Pricing</p>
                                                            <span>Corporate Package price</span>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        <img src={six} alt="icon"/>
                                                        <div className="info">
                                                            <p>FTP Server</p>
                                                            <span>FTP Server with providers</span>
                                                        </div>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-4">
                                            <ul className="mega-menu-item">
                                                <li>
                                                    <a href="#">
                                                        <img src={seven} alt="icon"/>
                                                        <div className="info">
                                                            <p>Support</p>
                                                            <span>Provide detailed explain</span>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        <img src={eight} alt="icon"/>
                                                        <div className="info">
                                                            <p>Pricing</p>
                                                            <span>Dedicated Package price</span>
                                                        </div>
                                                    </a>
                                                </li>

                                                <li>
                                                    <a href="#">
                                                        <img src={nine} alt="icon"/>
                                                        <div className="info">
                                                            <p>Services</p>
                                                            <span>Services with providers</span>
                                                        </div>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li className="menu-item hostie-has-dropdown mega-menu">
                            <a href="#" className="hostie-dropdown-main-element">Packages</a>
                            <div className="rts-mega-menu">
                                <div className="wrapper">
                                    <div className="row g-0">
                                        <div className="col-lg-6">
                                            <ul className="mega-menu-item">
                                                <li>
                                                    <a href="#">
                                                        <img src={twenty_two} alt="icon"/>
                                                        <div className="info">
                                                            <p>Home User Shared Package</p>
                                                            <span>Home User Internet</span>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        <img src={twenty_three} alt="icon"/>
                                                        <div className="info">
                                                            <p>Office User Shared Package</p>
                                                            <span>Office User Internet</span>
                                                        </div>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-6">
                                            <ul className="mega-menu-item">
                                                <li>
                                                    <a href="#">
                                                        <img src={twenty_five} alt="icon"/>
                                                        <div className="info">
                                                            <p>Corporate User Package</p>
                                                            <span>Corporate User Internet</span>
                                                        </div>
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href="#">
                                                        <img src={twenty_four} alt="icon"/>
                                                        <div className="info">
                                                            <p>Dedicated Package</p>
                                                            <span>Dedicated that gives you tools</span>
                                                        </div>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li className="menu-item hostie-has-dropdown">
                            <a href="#" className="hostie-dropdown-main-element">Service</a>
                            <ul className="hostie-submenu list-unstyled menu-pages">
                                <li className="nav-item"><a className="nav-link" href="#">PPPoE Service</a></li>
                                <li className="nav-item"><a className="nav-link" href="#">Static IP</a></li>
                                <li className="nav-item"><a className="nav-link" href="#">Cable TV </a></li>
                                <li className="nav-item"><a className="nav-link" href="#">Settop Box Digital</a></li>
                            </ul>
                        </li>

                        <li className="menu-item hostie-has-dropdown">
                            <a href="#" className="hostie-dropdown-main-element">FTP Link</a>
                            <ul className="hostie-submenu list-unstyled menu-pages">
                                <li className="nav-item"><a className="nav-link" href="#">Circle FTP</a></li>
                                <li className="nav-item"><a className="nav-link" href="#">ICC FTP</a></li>
                            </ul>
                        </li>

                        <li className="menu-item hostie-has-dropdown">
                            <a href="#" className="hostie-dropdown-main-element">Technology</a>
                            <ul className="hostie-submenu list-unstyled menu-pages">
                                <li className="nav-item"><a className="nav-link" href="#">BDIX</a></li>
                                <li className="nav-item"><a className="nav-link" href="#">CDN</a></li>
                                <li className="nav-item"><a className="nav-link" href="#">Game</a></li>
                            </ul>
                        </li>

                        <li className="menu-item hostie-has-dropdown">
                            <a href="#" className="hostie-dropdown-main-element">Help Center</a>
                            <ul className="hostie-submenu list-unstyled menu-pages">
                                <li className="nav-item"><a className="nav-link" href="#">FAQ</a></li>
                                <li className="nav-item"><a className="nav-link" href="#">Support</a></li>
                                <li className="nav-item"><a className="nav-link" href="#">Contact</a></li>
                                <li className="nav-item"><a className="nav-link" href="#">Blogs</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </nav>

            <div className="rts-header__right">
                <a href={route('customer.login')} className="login__btn" target="_blank">Client Login</a>
                <button id="menu-btn" className="mobile__active menu-btn" onClick={toggleSidebar}>
                    <i className="fa-sharp fa-solid fa-bars"></i>
                </button>
            </div>

            <div id="anywhere-home" className="">
            </div>
            <div id="side-bar" className={`side-bar header-two ${isSidebarOpen && isMobile ? 'open' : ''}`} ref={sidebarRef}>
                <button className="close-icon-menu d-flex" onClick={toggleSidebar}>
                    <span className="company_name">Power IT BD</span>
                    <i className="fa-sharp fa-thin fa-xmark"></i>
                </button>

                <div className="mobile-menu-main" style={{overflow:'scroll'}}>
                    <nav className="nav-main mainmenu-nav mt--30">
                        <ul className="mainmenu metismenu" id="mobile-menu-active">
                            <li className="has-droupdown active">
                                <a href="#" className="main">Home</a>
                            </li>
                            <li className={`has-droupdown ${openDropdowns[2] ? 'mm-active' : ''}`}>
                                <a href="#" className="main" onClick={() => toggleDropdown(2)}>Pages</a>
                                <ul className={`submenu mm-collapse ${openDropdowns[2] ? 'mm-show' : ''}`}>
                                    <li><a className="mobile-menu-link" href="#" onClick={handleLinkClick}>About</a></li>
                                    <li><a className="mobile-menu-link" href="#" onClick={handleLinkClick}>Affiliate</a></li>
                                    <li><a className="mobile-menu-link" href="#" onClick={handleLinkClick}>Pricing</a></li>
                                    <li><a className="mobile-menu-link" href="#" onClick={handleLinkClick}>Sign Up</a></li>
                                    <li><a className="mobile-menu-link" href="#" onClick={handleLinkClick}>Whois</a></li>
                                    <li><a className="mobile-menu-link" href="#" onClick={handleLinkClick}>Partner</a></li>
                                    <li><a className="mobile-menu-link" href="#" onClick={handleLinkClick}>Blog</a></li>
                                </ul>
                            </li>
                            <li className={`has-droupdown ${openDropdowns[3] ? 'mm-active' : ''}`}>
                                <a href="#" className="main" onClick={() => toggleDropdown(3)}>Domain</a>
                                <ul className={`submenu mm-collapse ${openDropdowns[3] ? 'mm-show' : ''}`}>
                                    <li><a className="mobile-menu-link" href="#" onClick={handleLinkClick}>Domain Checker</a></li>
                                    <li><a className="mobile-menu-link" href="#" onClick={handleLinkClick}>Domain Transfer</a></li>
                                </ul>
                            </li>
                            <li className={`has-droupdown ${openDropdowns[4] ? 'mm-active' : ''}`}>
                                <a href="#" className="main" onClick={() => toggleDropdown(4)}>Technology</a>
                                <ul className={`submenu mm-collapse ${openDropdowns[4] ? 'mm-show' : ''}`}>
                                    <li><a className="mobile-menu-link" href="#" onClick={handleLinkClick}>Technology</a></li>
                                    <li><a className="mobile-menu-link" href="#" onClick={handleLinkClick}>Data Center</a></li>
                                    <li><a className="mobile-menu-link" href="#" onClick={handleLinkClick}>Game Details</a></li>
                                </ul>
                            </li>
                            <li className={`has-droupdown ${openDropdowns[5] ? 'mm-active' : ''}`}>
                                <a href="#" className="main" onClick={() => toggleDropdown(5)}>Help Center</a>
                                <ul className={`submenu mm-collapse ${openDropdowns[5] ? 'mm-show' : ''}`}>
                                    <li><a className="mobile-menu-link" href="#" onClick={handleLinkClick}>Knowledgebase</a></li>
                                    <li><a className="mobile-menu-link" href="#" onClick={handleLinkClick}>Ads Banner</a></li>
                                    <li><a className="mobile-menu-link" href="#" onClick={handleLinkClick}>Whois</a></li>
                                    <li><a className="mobile-menu-link" href="#" onClick={handleLinkClick}>Support</a></li>
                                    <li><a className="mobile-menu-link" href="#" onClick={handleLinkClick}>Contact</a></li>
                                </ul>
                            </li>
                        </ul>
                    </nav>

                    <ul className="social-area-one pl--20 mt--100">
                        <li><a href="https://www.linkedin.com" aria-label="social-link" target="_blank"><i className="fa-brands fa-linkedin"></i></a></li>
                        <li><a href="https://www.x.com" aria-label="social-link" target="_blank"><i className="fa-brands fa-twitter"></i></a></li>
                        <li><a href="https://www.youtube.com" aria-label="social-link" target="_blank"><i className="fa-brands fa-youtube"></i></a></li>
                        <li><a href="https://www.facebook.com" aria-label="social-link" target="_blank"><i className="fa-brands fa-facebook-f"></i></a></li>
                    </ul>
                </div>
            </div>

            <style>{`
                .side-bar {
                    position: fixed;
                    top: 0;
                    right: 0;
                    width: 300px;
                    height: 100vh;
                    background-color: #fff;
                    box-shadow: -5px 0 10px rgba(0, 0, 0, 0.1);
                    transform: translateX(100%);
                    transition: transform 0.3s ease-in-out;
                    z-index: 1000;
                }
                .side-bar.open {
                    transform: translateX(0);
                }

                .sidebar-toggle-button {
                    position: fixed;
                    top: 20px;
                    right: 20px;
                    background-color: transparent;
                    border: none;
                    font-size: 1.5rem;
                    cursor: pointer;
                    z-index: 1100;
                }
                .side-bar button i {
                    color: #ffffff;
                    height: 30px;
                    width: 30px;
                    border-radius: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: 14px;
                    margin-top: 0px;
                    font-size: 20px;
                    background: var(--color-primary);
                    font-weight: 300;
                }

                .close-icon-menu {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    top: 10px;
                    right: 10px;
                    background: none;
                    border: none;
                    font-size: 1.5rem;
                    cursor: pointer;
                }
                
                .company_name {
                    font-size: 23px;
                    font-weight: bold;
                    color: brown;
                    margin-right: 20px;
                    text-align: center;         
                }
                
                .close-icon-menu i {
                    font-size: 1.2rem;              
                    color: #333;   
                    height: 30px;  
                    width: 30px;
                    border-radius: 0; 
                    margin-left: 14px;    
                }
            `}</style>

        </>
    );
}
